// @flow
import React from 'react';
import GlossaryLink from '../components/glossary-link';
import Link from '../components/link';

const pronunciation_terms = [
  {
    id: 'arscenic',
    name: 'Arscenic',
    ipa: [['ˈ', 'ɑːr', '.', 's', 'iː', 'n', '.', 'ɪ', 'k']],
    readable: ['AR-seen-ick'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Arscenic_Canyon">Canyon</Link> in North
        Wash, Utah
      </>
    ),
  },
  {
    id: 'attache',
    name: 'Attache',
    ipa: [['æ', '.', 't', 'æ', 'ˈ', 'ʃ', 'eɪ']],
    readable: ['at-ash-AY'],
    notes: <>Petzl carabiner</>,
  },
  {
    id: 'behunin',
    name: 'Behunin',
    ipa: [
      ['b', 'ə', 'ˈ', 'h', 'juː', '.', 'n', 'ɪ', 'n'],
      ['b', 'ə', 'ˈ', 'h', 'uː', '.', 'n', 'ɪ', 'n'],
    ],
    readable: ['buh-HUE-nin', 'buh-WHO-nin'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Behunin_Canyon">Canyon</Link> in Zion
        National Park
      </>
    ),
  },
  {
    id: 'biner',
    name: 'Biner',
    ipa: [['ˈ', 'b', 'i', 'n', 'ər']],
    readable: ['BEAN-er'],
    notes: (
      <>
        Short for <GlossaryLink>carabiner</GlossaryLink>
      </>
    ),
  },
  {
    id: 'bivouac',
    name: 'Bivouac',
    ipa: [['ˈ', 'b', 'ɪ', 'v', '.', 'uː', '.', 'æ', 'k']],
    readable: ['BIV-oo-ack'],
    notes: (
      <>
        <GlossaryLink>Bivouac</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'bogley',
    name: 'Bogley',
    ipa: [["'", 'b', 'oʊ', 'ɡ', '.', 'l', 'iː']],
    readable: ['BOW-glee'],
    notes: (
      <>
        Canyoneering{' '}
        <Link to="http://www.bogley.com/forum/forumdisplay.php?15-Canyoneering">
          Forum
        </Link>{' '}
        -{' '}
        <Link to="http://www.bogley.com/forum/showthread.php?60917-How-To-Bogley-Pronunciation&s=35724171c009b8fb4847bfd221f9f956&p=475934&viewfull=1#post475934">
          source
        </Link>
      </>
    ),
  },
  {
    id: 'boltergeist',
    name: 'Boltergeist',
    ipa: [['ˈ', 'b', 'oʊ', 'l', '.', 't', 'ər', '.', 'ɡ', 'aɪ', 's', 't']],
    readable: ['BOWL-ter-guy-st'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Boltergeist_Canyon">Canyon</Link> in Dixie
        National Forest, Utah
      </>
    ),
  },
  {
    id: 'cairn',
    name: 'Cairn',
    ipa: [['k', 'ɛər', 'n']],
    readable: ['CAIRN'],
    notes: (
      <>
        <GlossaryLink>Cairn</GlossaryLink> in glossary term
      </>
    ),
  },
  {
    id: 'carmel',
    name: 'Carmel',
    ipa: [
      ['k', 'ɑːr', 'ˈ', 'm', 'ʒ', 'l'],
      ['ˈ', 'k', 'ɑːr', '.', 'm', 'əl'],
    ],
    readable: ['car-MELL', 'CAR-mull'],
    notes: (
      <>
        First pronunciation is for{' '}
        <Link to="https://en.wikipedia.org/wiki/Carmel_Formation">
          Carmel Formation
        </Link>
        , second is for{' '}
        <Link to="https://en.wikipedia.org/wiki/Mount_Carmel_Junction,_Utah">
          Mount Carmel Junction
        </Link>
      </>
    ),
  },
  {
    id: 'constrychnine',
    name: 'Constrychnine',
    ipa: [['k', 'ən', 'ˈ', 's', 't', 'r', 'ɪ', 'k', '.', 'n', 'aɪ', 'n']],
    readable: ['con-STRICK-nine'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Constrychnine_Canyon">Canyon</Link> in
        Poison Springs, North Wash, Utah
      </>
    ),
  },
  {
    id: 'cordelette',
    name: 'Cordelette',
    ipa: [['ˌ', 'k', 'ɔər', 'd', '.', 'əl', 'ˈ', 'ɛ', 't']],
    readable: ['cord-uh-LET'],
    notes: (
      <>
        <GlossaryLink>Cordelette</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'critr',
    name: 'CRITR',
    ipa: [['ˈ', 'k', 'r', 'ɪ', 't', '.', 'ər']],
    readable: ['CRIT-er'],
    notes: (
      <>
        <GlossaryLink>CRITR</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'delorme',
    name: 'DeLorme',
    ipa: [['d', 'ɛ', 'ˈ', 'l', 'ʊr', '.', 'm', 'eɪ']],
    readable: ['deh-LORE-may'],
    notes: (
      <>
        Manufacturer of{' '}
        <GlossaryLink id="personal-locator-beacon">PLBs</GlossaryLink>. Bought
        by Garmin in 2016.
      </>
    ),
  },
  {
    id: 'eardley',
    name: 'Eardley',
    ipa: [['ˈ', 'ər', 'd', '.', 'l', 'i']],
    readable: ['URD-lee'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Eardley_Canyon_(Lower)">Canyon</Link> in
        San Rafael Swell
      </>
    ),
  },
  {
    id: 'englestead',
    name: 'Englestead',
    ipa: [['ˈ', 'iː', 'ŋ', '.', 'əl', '.', 's', 't', 'ʒ', 'd']],
    readable: ['ING-ul-sted'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Englestead_Hollow">Canyon</Link> in Zion
        National Park
      </>
    ),
  },
  {
    id: 'entrajo',
    name: 'Entrajo',
    ipa: [['ɛ', 'n', 'ˈ', 't', 'r', 'ɒ', '.', 'h', 'oʊ']],
    readable: ['en-TRAW-hoe'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Entrajo_Canyon">Canyon</Link> in Moab,
        Utah
      </>
    ),
  },
  {
    id: 'escalante',
    name: 'Escalante',
    ipa: [
      ['ˌ', 'ə', 's', '.', 'k', 'ə', 'ˈ', 'l', 'æ', 'n', 't'],
      ['ˌ', 'ə', 's', '.', 'k', 'ə', 'ˈ', 'l', 'æ', 'n', '.', 't', 'i'],
    ],
    readable: ['es-kuh-LANT', 'es-kuh-LAN-tee'],
    notes: (
      <>
        The city is pronounced either way, the national monument is the second
        pronunciation
      </>
    ),
  },
  {
    id: 'etrier',
    name: 'Etrier',
    ipa: [
      ['ˈ', 'eɪ', 't', '.', 'r', 'i', '.', 'eɪ'],
      ['ˈ', 'ɛ', 't', '.', 'r', 'i', '.', 'ər'],
    ],
    readable: ['AY-tree-ay', 'ETT-ree-er'],
    notes: (
      <>
        <GlossaryLink>Etrier</GlossaryLink> glossary term. First pronunciation
        is French, second is the English pronunciation of the French word.
      </>
    ),
  },
  {
    id: 'granary',
    name: 'Granary',
    ipa: [
      ['ˈ', 'ɡ', 'r', 'æ', 'n', '.', 'ər', '.', 'i'],
      ['ˈ', 'ɡ', 'r', 'eɪ', 'n', '.', 'ər', '.', 'i'],
    ],
    readable: ['GRAN-er-ee', 'GRAIN-er-ee'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Granary_Canyon">Canyon</Link> in Moab,
        Utah
      </>
    ),
  },
  {
    id: 'hueco',
    name: 'Hueco',
    ipa: [['uː', 'ˈ', 'ɛ', 'k', 'oʊ']],
    readable: ['WECK-oh'],
    notes: (
      <>
        <GlossaryLink>Hueco</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'icopro',
    name: 'ICOpro',
    ipa: [['ˈ', 'aɪ', '.', 'k', 'oʊ', '.', 'p', 'r', 'oʊ']],
    readable: ['EYE-koe-pro'],
    notes: (
      <Link to="http://www.icopro.org/">
        International Canyoning Organization for Professionals
      </Link>
    ),
  },
  {
    id: 'kaiparowits',
    name: 'Kaiparowits',
    ipa: [['k', 'aɪ', 'ˈ', 'p', 'ɑːr', '.', 'oʊ', '.', 'ɪ', 't', 's']],
    readable: ['kye-PAR-oh-its'],
    notes: (
      <>
        <Link to="https://en.wikipedia.org/wiki/Kaiparowits_Plateau">Area</Link>{' '}
        in Grand Staircase-Escalante National Monument
      </>
    ),
  },
  {
    id: 'kolob',
    name: 'Kolob',
    ipa: [['ˈ', 'k', 'oʊ', '.', 'l', 'ɔː', 'b']],
    readable: ['KOE-lob'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Kolob_Canyon">Canyon</Link> in Zion
        National Park
      </>
    ),
  },
  {
    id: 'leprechaun',
    name: 'Leprechaun',
    ipa: [['ˈ', 'l', 'ɛ', 'p', '.', 'r', 'ə', '.', 'k', 'ɒ', 'n']],
    readable: ['LEP-ruh-kon'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Leprechaun_Canyon_(disambiguation)">
          Canyons
        </Link>{' '}
        in North Wash, Utah
      </>
    ),
  },
  {
    id: 'lomatium',
    name: 'Lomatium',
    ipa: [['l', 'oʊ', 'ˈ', 'm', 'eɪ', '.', 'ʃ', 'iː', '.', 'əm']],
    readable: ['low-MAY-she-um'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Lomatium_Canyon">Canyon</Link> in Arches
        National Park
      </>
    ),
  },
  {
    id: 'macrame',
    name: 'Macrame',
    ipa: [['ˈ', 'm', 'æ', 'k', '.', 'r', 'ə', 'ˌ', 'm', 'eɪ']],
    readable: ['MACK-ruh-may'],
    notes: (
      <>
        <GlossaryLink>Macrame</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'mammut',
    name: 'Mammut',
    ipa: [['m', 'æ', 'ˈ', 'm', 'u', 't']],
    readable: ['ma-MOOT'],
    notes: (
      <Link to="https://www.mammut.com/us/en">Mountaineering company</Link>
    ),
  },
  {
    id: 'moab',
    name: 'Moab',
    ipa: [['ˈ', 'm', 'oʊ', '.', 'æ', 'b']],
    readable: ['MOH-ab'],
    notes: <>City in Utah</>,
  },
  {
    id: 'mokimoqui',
    name: 'Moki/Moqui',
    ipa: [['ˈ', 'm', 'oʊ', '.', 'k', 'i']],
    readable: ['MOH-key'],
    notes: (
      <>
        <GlossaryLink>Moqui</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'mukuntuweap',
    name: 'Mukuntuweap',
    ipa: [['m', 'ə', 'ˈ', 'k', 'uː', 'n', '.', 't', 'uː', '.', 'w', 'iː', 'p']],
    readable: ['muh-KUNE-too-weep'],
    notes: (
      <>
        Previous name for Zion National Park. Believed to mean "Straight up
        land".
      </>
    ),
  },
  {
    id: 'ouray',
    name: 'Ouray',
    ipa: [['ˈ', 'j', 'ər', '.', 'eɪ']],
    readable: ['YER-ay'],
    notes: <>Canyoneering area in Colorado</>,
  },
  {
    id: 'paria',
    name: 'Paria',
    ipa: [['p', 'ə', 'ˈ', 'r', 'i', '.', 'ə']],
    readable: ['puh-REE-uh'],
    notes: <>River in Utah/Arizona</>,
  },
  {
    id: 'parunuweap',
    name: 'Parunuweap',
    ipa: [['p', 'ə', 'ˈ', 'r', 'u', '.', 'n', 'u', '.', 'w', 'iː', 'p']],
    readable: ['puh-ROO-noo-weep'],
    notes: <>River in Zion National Park</>,
  },
  {
    id: 'petzl',
    name: 'Petzl',
    ipa: [['ˈ', 'p', 'ɛ', 't', '.', 's', 'əl']],
    readable: ['PET-sull'],
    notes: <>Gear Manufacturer</>,
  },
  {
    id: 'piton',
    name: 'Piton',
    ipa: [['ˈ', 'p', 'i', '.', 't', 'ɒ', 'n']],
    readable: ['PEE-tawn'],
    notes: (
      <>
        <GlossaryLink>Piton</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'pleiades',
    name: 'Pleiades',
    ipa: [['ˈ', 'p', 'l', 'i', '.', 'ə', 'ˌ', 'd', 'iː', 'z']],
    readable: ['PLEE-uh-deez'],
    notes: (
      <span>
        <Link to="http://ropewiki.com/Pleiades_Canyon">Canyon</Link> in Moab,
        Utah
      </span>
    ),
  },
  {
    id: 'potable',
    name: 'Potable',
    ipa: [
      ['ˈ', 'p', 'oʊ', 't', '.', 'ə', 'ˌ', 'b', 'əl'],
      ['ˈ', 'p', 'ɔː', 't', '.', 'ə', 'ˌ', 'b', 'əl'],
    ],
    readable: ['POTE-uh-bull', 'POT-uh-bull'],
    notes: (
      <>
        <GlossaryLink>Potable</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'potash',
    name: 'Potash',
    ipa: [['ˈ', 'p', 'ɒ', 't', '.', 'æ', 'ʃ']],
    readable: ['POT-ash'],
    notes: <>Area near Moab, Utah</>,
  },
  {
    id: 'prusik',
    name: 'Prusik',
    ipa: [['ˈ', 'p', 'r', 'ʌ', 's', '.', 'ɪ', 'k']],
    readable: ['PRUSS-ick'],
    notes: (
      <>
        <GlossaryLink>Prusik</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'rodcle',
    name: 'Rodcle',
    ipa: [
      ['ˈ', 'r', 'oʊ', 'd', '.', 'k', 'l', 'ɛ'],
      ['ˈ', 'r', 'ɒ', 'd', '.', 'k', 'l', 'i'],
    ],
    readable: ['ROAD-cleh', 'ROD-klee'],
    notes: (
      <>
        Canyoneering gear manufacturer in Spain. First pronunciation is the
        Spanish pronunciation, second is how I've heard it in English.
      </>
    ),
  },
  {
    id: 'segers',
    name: 'Segers',
    ipa: [['ˈ', 's', 'iː', 'ɡ', 'ʊr', 's']],
    readable: ['SEE-gerz'],
    notes: (
      <>
        Another name for{' '}
        <Link to="http://ropewiki.com/The_Squeeze">The Squeeze</Link>
      </>
    ),
  },
  {
    id: 'shash-jaa',
    name: "Shash Jaa'",
    ipa: [['ʃ', 'ɑː', 'ʃ', 'dʒ', 'ɒ']],
    readable: ['shaw-sh jaw'],
    notes: <>"Bears Ears" in Navajo</>,
  },
  {
    id: 'shillelagh',
    name: 'Shillelagh',
    ipa: [['ʃ', 'əl', 'ˈ', 'eɪ', '.', 'l', 'i']],
    readable: ['shu-LAY-lee'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Shillelagh_Canyon">Canyon</Link> in North
        Wash, Utah
      </>
    ),
  },
  {
    id: 'sinawava',
    name: 'Sinawava',
    ipa: [['ˌ', 's', 'ɪ', 'n', '.', 'ə', 'ˈ', 'w', 'ɔː', '.', 'v', 'ɔː']],
    readable: ['sin-uh-WAH-vah'],
    notes: <>Area in Zion National Park</>,
  },
  {
    id: 'slideanide',
    name: 'Slideanide',
    ipa: [['ˈ', 's', 'l', 'aɪ', 'd', '.', 'ə', '.', 'n', 'aɪ', 'd']],
    readable: ['SLIDE-uh-nide'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Slideanide_Canyon">Canyon</Link> in Poison
        Springs, Utah
      </>
    ),
  },
  {
    id: 'smallcatraz',
    name: 'Smallcatraz',
    ipa: [['ˈ', 's', 'm', 'ɒ', 'l', '.', 'k', 'ə', '.', 't', 'r', 'æ', 'z']],
    readable: ['SMALL-kuh-traz'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Smallcatraz">Canyon</Link> in Robber's
        Roost, Utah
      </>
    ),
  },
  {
    id: 'spelegyca',
    name: 'Spelegyca',
    ipa: [['ˌ', 's', 'p', 'ɛ', 'l', '.', 'ə', 'ˈ', 'dʒ', 'iː', '.', 'k', 'ə']],
    readable: ['spell-uh-GEE-kuh'],
    notes: (
      <>
        <GlossaryLink>Spelegyca</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'sqwurel',
    name: 'SQWUREL',
    ipa: [['s', 'k', 'w', 'ˈ', 'ɜːr', 'əl']],
    readable: ['Squirrel'],
    notes: (
      <>
        <GlossaryLink>SQWUREL</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'tibloc',
    name: 'Tibloc',
    ipa: [['ˈ', 't', 'ɪ', '.', 'b', 'l', 'ɔː', 'k']],
    readable: ['TIB-lock'],
    notes: (
      <>
        <GlossaryLink>Tibloc</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'topo',
    name: 'Topo',
    ipa: [
      ['ˈ', 't', 'ɔː', '.', 'p', 'oʊ'],
      ['ˈ', 't', 'oʊ', '.', 'p', 'oʊ'],
    ],
    readable: ['TOP-oh', 'TOPE-oh'],
    notes: (
      <>
        <GlossaryLink>Topo</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'trachyotomy',
    name: 'Trachyotomy',
    ipa: [
      [
        'ˌ',
        't',
        'r',
        'eɪ',
        '.',
        'k',
        'iː',
        'ˈ',
        'ɒ',
        't',
        '.',
        'ə',
        '.',
        'm',
        'i',
      ],
    ],
    readable: ['tray-key-OTT-uh-mee'],
    notes: (
      <>
        <Link to="http://ropewiki.com/Trachyotomy_Canyon">Canyon</Link> in North
        Wash, Utah
      </>
    ),
  },
  {
    id: 'traxion',
    name: 'Traxion',
    ipa: [['ˈ', 't', 'r', 'æ', 'k', '.', 'ʃ', 'ən']],
    readable: ['TRACK-shun'],
    notes: (
      <>
        <GlossaryLink id="micro-traxion">Traxion</GlossaryLink> glossary term
      </>
    ),
  },
  {
    id: 'tyrolean',
    name: 'Tyrolean',
    ipa: [['t', 'aɪ', 'ˈ', 'r', 'oʊ', '.', 'l', 'i', '.', 'ən']],
    readable: ['tie-ROLL-ee-un'],
    notes: (
      <>
        <GlossaryLink>Tyrolean Traverse</GlossaryLink>
      </>
    ),
  },
  {
    id: 'zion',
    name: 'Zion',
    ipa: [['ˈ', 'z', 'aɪ', '.', 'ən']],
    readable: ['ZY-un'],
    notes: <>National Park in Utah</>,
  },
];

export default pronunciation_terms;
