// @flow
import styled from 'styled-components';
import { small_screen } from '../utils/styles';

export const Table = styled.table`
  ${small_screen} {
    display: block;
  }
`;

export const THead = styled.thead`
  ${small_screen} {
    display: none;
  }
`;

export const Tr = styled.tr`
  ${small_screen} {
    display: block;
  }
`;

export const Td = styled.td`
  &:first-child {
    vertical-align: top;
  }

  ${small_screen} {
    display: block;
    padding-left: 0;
    &:first-child {
      text-align: center;
      font-weight: bold;
    }
    &:not(:last-child) {
      border: none;
    }
  }
`;
