// @flow
const pronunciation_ipa = {
  b: "'b' in 'buy'",
  d: "'d' in 'dye'",
  dʒ: "/dʒ/: 'j' in 'jam'",
  ð: "/ð/: 'th' in 'thy'",
  f: "'f' in 'find'",
  ɡ: "/ɡ/: 'g' in 'guy'",
  h: "'h' in 'hi'",
  hw: "/hw/: 'wh' in 'why'",
  j: "/j/: 'y' in 'yes'",
  k: "'k' in 'kind'",
  l: "'l' in 'lie'",
  m: "'m' in 'my'",
  n: "'n' in 'nigh'",
  ŋ: "/ŋ/: 'ng' in 'sing'",
  p: "'p' in 'pie'",
  r: "'r' in 'rye'",
  s: "'s' in 'sigh'",
  ʃ: "/ʃ/: 'sh' in 'shy'",
  t: "'t' in 'tie'",
  tʃ: "/tʃ/: 'ch' in 'China'",
  θ: "/θ/: 'th' in 'thigh'",
  v: "'v' in 'vie'",
  w: "'w' in 'wind'",
  z: "'z' in 'zoom'",
  ʒ: "/ʒ/: 's' in 'pleasure'",
  ɑː: "/ɑː/: 'a' in 'father'",
  ɑːr: "/ɑːr/: 'ar' in 'far'",
  ɒ: "/ɒ/: 'o' in 'body'",
  ɒr: "/ɒr/: 'or' in 'moral'",
  æ: "/æ/: 'a' in 'bad'",
  ær: "/ær/: 'arr' in 'marry'",
  aɪ: "/aɪ/: 'i' in 'tide'",
  aɪər: "/aɪər/: 'ire' in 'fire'",
  aʊ: "/aʊ/: 'ou' in 'mouth'",
  aʊər: "/aʊər/: 'our' in 'hour'",
  ɛ: "/ɛ/: 'e' in 'dress'",
  ɛr: "/ɛr/: 'err' in 'merry'",
  eɪ: "/eɪ/: 'a' in 'face'",
  ɛər: "/ɛər/: 'are' in 'bare'",
  ɪ: "/ɪ/: 'i' in 'kit'",
  ɪr: "/ɪr/: 'irr' in 'mirror'",
  iː: "/iː/: 'ee' in 'fleece'",
  ɪər: "/ɪər/: 'ear' in 'near'",
  ɔː: "/ɔː/: 'au' in 'fraud'",
  ɔːr: "/ɔːr/: 'ar' in 'war'",
  ɔɪ: "/ɔɪ/: 'oi' in 'choice'",
  ɔɪər: "/ɔɪər/: 'oir' in 'loir'",
  oʊ: "/oʊ/: 'o' in 'code'",
  ɔər: "/ɔər/: 'ore' in 'wore'",
  ʊ: "/ʊ/: 'oo' in 'foot'",
  ʊr: "/ʊr/: 'our' in 'courier'",
  uː: "/uː/: 'oo' in 'goose'",
  ʊər: "/ʊər/: 'our' in 'tour'",
  juː: "/juː/: 'u' in 'cute'",
  jʊər: "/jʊər/: 'ure' in 'cure'",
  ʌ: "/ʌ/: 'u' in 'cut'",
  ɜːr: "/ɜːr/: 'ur' in 'fur'",
  ʌr: "/ʌr/: 'urr' in 'hurry'",
  ə: "/ə/: 'a' in 'about'",
  ər: "/ər/: 'er' in 'letter'",
  əl: "/əl/: 'le' in 'bottle'",
  ən: "/ən/: 'on' in 'button'",
  əm: "/əm/: 'm' in 'rhythm'",
  i: "/iː/ or /ɪ/: 'y' in 'happy'",
  u: "/uː/ or /ʊ/: 'u' in 'influence'",
  ˈ: '/ˈ/: primary stress follows',
  ˌ: '/ˌ/: secondary stress follows',
  '.': '/./: syllable break',
};

export default pronunciation_ipa;
