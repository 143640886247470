// @flow
import React from 'react';
import styled from 'styled-components';
import { Layout, Link, withFrontMatter } from '../components';
import { Table, Tr, Td as TableData, THead } from '../components/table';
import pronunciation_ipa from '../data/pronunciation-ipa';
import pronunciation_terms from '../data/pronunciation-terms';
import type { FrontMatter } from '../utils/types';
import { color_text } from '../utils/styles';

const LinkedTerm = styled(Link).attrs({
  hideExternalLink: true,
})`
  color: unset;

  &:hover,
  &:active {
    color: unset;
    text-decoration: underline ${color_text} solid;
  }
`;

const Td = styled(TableData)`
  &:first-child {
    vertical-align: middle;
  }
`;

class Pronunciation extends React.Component<{ frontMatter: FrontMatter }> {
  href = 'https://en.wikipedia.org/wiki/Help:IPA/English';
  terms = [];

  constructor() {
    super();

    const char2Node = (ipa, key) => (
      <Link
        to={this.href}
        key={key}
        title={pronunciation_ipa[ipa]}
        hideExternalLink
      >
        {ipa}
      </Link>
    );

    const ipaWord2NodeArray = (ipaWord) => {
      const key = ipaWord.join('');
      const nodeArray = [<br key={key} />];
      ipaWord.forEach((char, index) => {
        nodeArray.push(char2Node(char, `${key}-${index}`));
      });
      return nodeArray;
    };

    const ipaWords2NodeArray = (ipaWords) => {
      const nodeArray = [];
      ipaWords.forEach((ipaWord) => {
        const array = ipaWord2NodeArray(ipaWord);
        array.forEach((node) => {
          nodeArray.push(node);
        });
      });
      nodeArray.splice(0, 1);
      return nodeArray;
    };

    const readable2NodeArray = (readable) => {
      const nodeArray = [];
      readable.forEach((word, index) => {
        nodeArray.push(<br key={index + 'br'} />);
        nodeArray.push(<span key={index}>{word}</span>);
      });
      nodeArray.splice(0, 1);
      return nodeArray;
    };

    this.terms = pronunciation_terms.map((term) => {
      const ipaArray = ipaWords2NodeArray(term.ipa);

      const readableArray = readable2NodeArray(term.readable);

      return (
        <Tr id={term.id} key={term.id}>
          <Td>
            <LinkedTerm to={`#${term.id}`}>{term.name}</LinkedTerm>
          </Td>
          <Td>{ipaArray}</Td>
          <Td>{readableArray}</Td>
          <Td>{term.notes}</Td>
        </Tr>
      );
    });
  }

  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <Table>
          <THead>
            <Tr>
              <th>Term</th>
              <th>
                <Link to={this.href}>IPA</Link>
              </th>
              <th>Readable</th>
              <th>Notes</th>
            </Tr>
          </THead>
          <tbody>{this.terms}</tbody>
        </Table>
      </Layout>
    );
  }
}

export default withFrontMatter('pronunciation')(Pronunciation);
